<template>
  <dash-page-new>

    <template #header_action>
      <ws-button @click="openAddPrice" label="AddPrice"></ws-button>
    </template>

    <template #default >
      <ws-navigation
          v-model="navigation"
          :items="navigationSelect"
          no-number
      >
        <template #item.prices>
          <ws-data-table
              :items="pricesSelect"
              :headers="pricesHeaders"
          >

            <template #item.price="{item}">
              <h5>{{ item.price }} USD</h5>
            </template>

          </ws-data-table>
        </template>

        <template #item.courses>
          <ws-data-table
              :items="courses"
              :headers="coursesHeaders"
          >

            <template #item.apple_price="{item}">
              <ft-select
                  @input="setCoursePrice($event , item)"
                  :items="pricesSelect"
              >
                <h5 class="wsDARKER">
                  {{ item.apple_price }} USD
                  <v-icon>mdi-menu-down</v-icon>
                </h5>
              </ft-select>

            </template>

            <template #item.android_price="{item}">
              <ft-select
                  @input="setCoursePrice($event , item)"
                  :items="pricesSelect"
              >
                <h5 class="wsDARKER">
                  {{ item.android_price }} USD
                  <v-icon>mdi-menu-down</v-icon>
                </h5>
              </ft-select>

            </template>

            <template #item.is_setup="{item}">
              <v-icon :color="item.is_setup ? wsSUCCESS : wsBACKGROUND">mdi-check-circle-outline</v-icon>
            </template>

          </ws-data-table>
        </template>


      </ws-navigation>
    </template>

    <template #dialog>
      <ws-dialog
          v-if="displayPriceDialog"
          v-model="displayPriceDialog"
          :title="$t('AddPrice')"
          @save="addEditPrice"
      >
        <ws-text-field
          v-model="entityData.name"
          :label="$t('Name')"
      />
        <ws-text-field
            v-model="entityData.price"
            :label="$t('Price')"
            number
            class="mt-5"
        />

        <ws-text-field
            v-model="entityData.apple_id"
            :label="$t('AppleId')"
            class="mt-5"
        />

        <ws-text-field
            v-model="entityData.android_id"
            :label="$t('AndroidId')"
            class="mt-5"
        />

      </ws-dialog>
    </template>

  </dash-page-new>
</template>


<script>
import {mapActions} from "vuex";

export default {
  name: "DashMobileAppsInAppPurchase",
  data() {
    return {
      displayPriceDialog : false,
      navigation : 'prices',
      entityData : {},
      pricesSelect : [],
      courses : [
        { name : 'Javascript від А до Я для початківців' ,
          payment_plan_name : null,
          apple_price : '14,99',
          android_price : '14,99',
          is_setup : true
        },
        { name : 'MongoDB від А до Я для початківців' ,
          payment_plan_name : null,
          apple_price : '14,99',
          android_price : '14,99',
          is_setup : true
        },
        { name : 'Android Studio від А до Я для початківців' ,
          payment_plan_name : 'Базовий',
          apple_price : '14,99',
          android_price : '14,99',
          is_setup : true
        },
        { name : 'Android Studio  від А до Я для початківців' ,
          payment_plan_name : 'Елітний+',
          apple_price : '16,99',
          android_price : '16,99',
          is_setup : true
        },
        { name : 'Android Studio від А до Я для початківців' ,
          payment_plan_name : 'Персональний',
          apple_price : '26,99',
          android_price : '26,99',
          is_setup : false
        },
      ],

    }
  },
  computed : {
    coursesHeaders() {
      return [
        { text : this.$t('Course')    , value : 'name' },
        { text : this.$t('PaymentPlan')   , value : 'payment_plan_name' },
        { text : this.$t('ApplePrice')  , value : 'apple_price' },
        { text : this.$t('AndroidPrice')  , value : 'android_price' },
        { text : this.$t('Readiness')  , value : 'is_setup' },
      ]
    },

    pricesHeaders() {
      return [
        { text : this.$t('Name')    , value : 'name' },
        { text : this.$t('Price')   , value : 'price' },
        { text : this.$t('AppleId')  , value : 'apple_id' },
        { text : this.$t('AppleVersion')  , value : 'apple_version' },
        { text : this.$t('AndroidId')  , value : 'android_id' },
        { text : this.$t('AndroidVersion')  , value : 'android_version' },
      ]
    },
    navigationSelect() {
      return [
        { text : this.$t('Prices') , value : 'prices'},
        { text : this.$t('CoursePrices') , value : 'courses'},
      ]
    },
  },
  methods : {
    ...mapActions('mobileapps' , [
      'GET_MOBILE_IN_APP_PRODUCTS',
    ]),


    setCoursePrice(price , course) {
      course.apple_price = price
    },
    openAddPrice() {
      this.entityData = {}
      this.displayPriceDialog = true
    },
    addEditPrice() {
      this.notify(this.entityData)
      this.pricesSelect.push(this.COPY(this.entityData))
      this.displayPriceDialog = false
    },

    async initPage() {
      alert(' i want ')
      let result = await this.GET_MOBILE_IN_APP_PRODUCTS()
      if (!result)  {
        return this.ERROR()
      }
      this.pricesSelect = result
    }
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>

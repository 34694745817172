var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-page-new',{scopedSlots:_vm._u([{key:"header_action",fn:function(){return [_c('ws-button',{attrs:{"label":"AddPrice"},on:{"click":_vm.openAddPrice}})]},proxy:true},{key:"default",fn:function(){return [_c('ws-navigation',{attrs:{"items":_vm.navigationSelect,"no-number":""},scopedSlots:_vm._u([{key:"item.prices",fn:function(){return [_c('ws-data-table',{attrs:{"items":_vm.pricesSelect,"headers":_vm.pricesHeaders},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('h5',[_vm._v(_vm._s(item.price)+" USD")])]}}])})]},proxy:true},{key:"item.courses",fn:function(){return [_c('ws-data-table',{attrs:{"items":_vm.courses,"headers":_vm.coursesHeaders},scopedSlots:_vm._u([{key:"item.apple_price",fn:function(ref){
var item = ref.item;
return [_c('ft-select',{attrs:{"items":_vm.pricesSelect},on:{"input":function($event){return _vm.setCoursePrice($event , item)}}},[_c('h5',{staticClass:"wsDARKER"},[_vm._v(" "+_vm._s(item.apple_price)+" USD "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)])]}},{key:"item.android_price",fn:function(ref){
var item = ref.item;
return [_c('ft-select',{attrs:{"items":_vm.pricesSelect},on:{"input":function($event){return _vm.setCoursePrice($event , item)}}},[_c('h5',{staticClass:"wsDARKER"},[_vm._v(" "+_vm._s(item.android_price)+" USD "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)])]}},{key:"item.is_setup",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.is_setup ? _vm.wsSUCCESS : _vm.wsBACKGROUND}},[_vm._v("mdi-check-circle-outline")])]}}])})]},proxy:true}]),model:{value:(_vm.navigation),callback:function ($$v) {_vm.navigation=$$v},expression:"navigation"}})]},proxy:true},{key:"dialog",fn:function(){return [(_vm.displayPriceDialog)?_c('ws-dialog',{attrs:{"title":_vm.$t('AddPrice')},on:{"save":_vm.addEditPrice},model:{value:(_vm.displayPriceDialog),callback:function ($$v) {_vm.displayPriceDialog=$$v},expression:"displayPriceDialog"}},[_c('ws-text-field',{attrs:{"label":_vm.$t('Name')},model:{value:(_vm.entityData.name),callback:function ($$v) {_vm.$set(_vm.entityData, "name", $$v)},expression:"entityData.name"}}),_c('ws-text-field',{staticClass:"mt-5",attrs:{"label":_vm.$t('Price'),"number":""},model:{value:(_vm.entityData.price),callback:function ($$v) {_vm.$set(_vm.entityData, "price", $$v)},expression:"entityData.price"}}),_c('ws-text-field',{staticClass:"mt-5",attrs:{"label":_vm.$t('AppleId')},model:{value:(_vm.entityData.apple_id),callback:function ($$v) {_vm.$set(_vm.entityData, "apple_id", $$v)},expression:"entityData.apple_id"}}),_c('ws-text-field',{staticClass:"mt-5",attrs:{"label":_vm.$t('AndroidId')},model:{value:(_vm.entityData.android_id),callback:function ($$v) {_vm.$set(_vm.entityData, "android_id", $$v)},expression:"entityData.android_id"}})],1):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }